 import { animate, inView, stagger, spring } from "motion";

const hamburgerMenu = document.querySelectorAll(".mobile-menu-button");
const nav = document.querySelector(".mobile-navigation");
let galleryAnimationHasRun = false;

hamburgerMenu.forEach((button) => {
  button.addEventListener("click", () => {
    nav.classList.toggle("active");
    animateMobileMenu();
  });
});

function animateMobileMenu(x_offset = -50) {
  const sequence = [
    [
      ".mobile-navigation .site-logo",
      { y: [x_offset, 0], opacity: [0, 1] },
      { duration: 0.3 },
    ],
    [
      ".mobile-navigation ul li",
      { y: [-75, 15, -5, 0], opacity: [0, 1] },
      {
        duration: 0.4,
        delay: stagger(0.075),
        at: "-0.2",
      },
    ],
  ];

  animate(sequence);
}

function animateServiceDetails() {
  let animationIndex = 1;

  inView(
    ".aboutus > *:not(.linedot1), .bullet, .projecthead",
    ({ target }) => {
      animate(
        target,
        { y: [-50, 10, 0], opacity: [0, 1] },
        {
          delay: animationIndex * 0.25,
          duration: 0.3,
        }
      );
      animationIndex += 0.25;
    },
    { amount: 0.5 }
  );

  inView(
    ".project-in-focus, .grid--fw li",
    ({ target }) => {
      animate(
        target,
        { x: [-50, 10, 0], opacity: [0, 1] },
        {
          delay: animationIndex * 0.25,
          duration: 0.3,
        }
      );
      animationIndex += 0.25;
    },
    { amount: 0.25 }
  );

  window.addEventListener("scroll", () => {
    animationIndex = 0; // Zurücksetzen des Indexes nach jedem Scrollen
  });
}

function animateGenericElements() {
  let genericAnimationIndex = 1.75;

  if (document.getElementsByClassName("linedot2").length)
    animate(
      ".linedot2",
      { opacity: [0, 1], y: [-150, 0] },
      { delay: stagger(0.075) }
    );

  inView(
    ".leistungsref",
    ({ target }) => {
      animate(
        target,
        { x: [-50, 10, 0], opacity: 1 },
        {
          delay: genericAnimationIndex * 0.25,
          duration: 0.35,
        }
      );
      genericAnimationIndex += 0.3;
    },
    { amount: 0.7 }
  );

  window.addEventListener("scroll", () => {
    genericAnimationIndex = 0; // Zurücksetzen des Indexes nach jedem Scrollen
  });
}

function handleResizeAndOrientation() {
  if (
    window.matchMedia("(min-width: 768px)").matches &&
    !galleryAnimationHasRun
  ) {
    if (document.getElementsByClassName("grid-item").length) {
      animate(
        ".grid-item",
        { opacity: [0, 1] },
        {
          delay: stagger(0.05),
          duration: 0.5,
        }
      );

      animate(
        ".grid-item div",
        { opacity: [0, 1], x: [-50, 10, 0] },
        {
          delay: stagger(0.025, { startDelay: 0.15 }),
          duration: 0.25,
        }
      );

      galleryAnimationHasRun = true;
    }
  }
}

// Events überwachen
window.addEventListener("resize", handleResizeAndOrientation);
window.addEventListener("orientationchange", handleResizeAndOrientation);

window.addEventListener("load", () => {
  if (document.getElementById("lightning-bolt")) {
    animate(
      "#lightning-bolt",
      { opacity: [1, 0.15, 0.85, 0.4, 1] },
      { duration: 0.6, repeatDelay: 1.8, repeat: Infinity, delay: 1.2 }
    );
  }

  animateGenericElements();
  animateServiceDetails();

  if (document.querySelectorAll(".dots").length)
    animate(
      ".dots path",
      { opacity: [0, 1], fill: ['#000', '#fff', '#000'] },
      { delay: stagger(0.0075, {startDelay: 1.4}), duration: .225, repeat: Infinity, repeatDelay: 4 }
    );

  // Breadcrumbs
  if (document.getElementsByClassName("gallerynav").length)
    animate(".gallerynav", { opacity: [0, 1] }, { duration: 0.75 });

  // Sidebars
  if (document.getElementsByClassName("sidebar").length)
    animate(
      ".sidebar li, .sidebar h4",
      { opacity: [0, 1], x: [-50, 0] },
      { delay: stagger(0.075) }
    );

  // Single Project

  if (document.getElementById("slidercontainer"))
    animate(
      "#slidercontainer",
      { opacity: [0, 1], y: [-50, 15, -5, 0] },
      { duration: 0.4 }
    );

  // Portfolio
  if (document.getElementById("gallery"))
    inView("#gallery", ({ target }) => {
      if (document.getElementsByClassName("mobile-filters").length)
        animate(
          ".galnav li, .mobile-filters li",
          { opacity: [0, 1], x: [-50, 0] },
          {
            delay: stagger(0.05),
          }
        );

      handleResizeAndOrientation();
    });

  // Contact
  if (document.getElementById("gmapcontainer")) {
    let contactDelay = 0;

    inView(
      "#gmapcontainer",
      ({ target }) => {
        animate(
          target,
          { opacity: [0, 1], y: [-50, 10, 0] },
          {
            duration: 0.3,
            delay: contactDelay * 0.25,
          }
        );
        contactDelay += 0.4;
      },
      { amount: 0.25 }
    );

    inView(
      ".contact",
      ({ target }) => {
        animate(
          ".contact .sixcol:first-child > *",
          { opacity: [0, 1], y: [-50, 10, 0] },
          {
            duration: 0.3,
            delay: stagger(0.075, { startDelay: 0.25 }),
          }
        );
        contactDelay += 0.4;
      },
      { amount: 0.25 }
    );

    inView(
      "#contact-form",
      ({ target }) => {
        animate(
          ".contactform-field, .button-submit",
          { opacity: [0, 1], y: [-50, 10, 0] },
          {
            duration: 0.2,
            delay: stagger(0.075, { startDelay: 0.25 }),
          }
        );
      },
      { amount: 0.25 }
    );
  }
});
